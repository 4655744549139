import { IS_GTAG_ENABLED, IS_XTREMEPUSH_ENABLED } from '@/utils/constants';

export const initSignupTrack = (userId: string): void => {
  if (IS_XTREMEPUSH_ENABLED === 'true') {
    window.xtremepush('set', 'user_id', userId, function () {
      window.xtremepush('event', 'complete_registration');
    });
  }
};

export const initLoginTrack = (userId: string): void => {
  if (IS_XTREMEPUSH_ENABLED === 'true') {
    window.xtremepush('set', 'user_id', userId, function () {
      window.xtremepush('event', 'gamezone_login');
    });
  }
};

export function triggerXtremepushEvent(
  eventType: string,
  eventName: string,
  eventData?: string,
): void {
  if (IS_XTREMEPUSH_ENABLED === 'true') {
    if (eventData) {
      window.xtremepush(eventType, eventName, eventData);
    } else {
      window.xtremepush(eventType, eventName);
    }
  }
}

export function triggerGTAGEvent(eventName: string): void {
  if (IS_GTAG_ENABLED === 'true') {
    window.dataLayer.push({ event: eventName });
  }
}
