const config = window.config;

if (!config) {
  window.config = {
    REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
    REACT_APP_GAMEZONE_TILES: process.env.REACT_APP_GAMEZONE_TILES,
    REACT_APP_TENANT: process.env.REACT_APP_TENANT,
    REACT_APP_CLIENT_CODE: process.env.REACT_APP_CLIENT_CODE,
    REACT_APP_X_PUSH_URL: process.env.REACT_APP_X_PUSH_URL,
    REACT_APP_THREE_STEP_SIGNUP: process.env.REACT_APP_THREE_STEP_SIGNUP,
    REACT_APP_IS_GTAG_ENABLED: process.env.REACT_APP_IS_GTAG_ENABLED,
    REACT_APP_IS_XTREMEPUSH_ENABLED:
      process.env.REACT_APP_IS_XTREMEPUSH_ENABLED,
    REACT_APP_GTM_TOKEN: process.env.REACT_APP_GTM_TOKEN,
    REACT_APP_XTREMEPUSH_TOKEN: process.env.REACT_APP_XTREMEPUSH_TOKEN,
    REACT_APP_ENABLED_LANGUAGES: process.env.REACT_APP_ENABLED_LANGUAGES,
    REACT_APP_IS_HEADER_HIDDEN: process.env.REACT_APP_IS_HEADER_HIDDEN,
  };
}

export const API_BASE_URL = window.config.REACT_APP_BASE_URL as string;
export const GAMEZONE_TILES_URL = window.config
  .REACT_APP_GAMEZONE_TILES as string;
export const TENANT_CODE = window.config.REACT_APP_TENANT as string;
export const CLIENT_CODE = window.config.REACT_APP_CLIENT_CODE as string;
export const X_PUSH_URL = window.config.REACT_APP_X_PUSH_URL as string;
export const THREE_STEP_SIGNUP = window.config
  .REACT_APP_THREE_STEP_SIGNUP as string;
export const IS_GTAG_ENABLED = window.config
  .REACT_APP_IS_GTAG_ENABLED as string;
export const IS_XTREMEPUSH_ENABLED = window.config
  .REACT_APP_IS_XTREMEPUSH_ENABLED as string;

export const GTM_TOKEN = window.config.REACT_APP_GTM_TOKEN as string;

export const XTREMEPUSH_TOKEN = window.config
  .REACT_APP_XTREMEPUSH_TOKEN as string;

export const ENABLED_LANGUAGES = window.config
  .REACT_APP_ENABLED_LANGUAGES as string;

export const IS_HEADER_HIDDEN =
  window.config.REACT_APP_IS_HEADER_HIDDEN === 'true';
