import React, { createContext, useCallback, useMemo, useState } from 'react';

type ProgressContextType = {
  isEnabledProgress: boolean;
  onChangeProgressEnabled: (value: boolean) => void;
};

const ProgressContext = createContext<ProgressContextType>({
  isEnabledProgress: false,
  onChangeProgressEnabled: () => undefined,
});

const ProgressContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isEnabledProgress, setIsEnabledProgress] = useState(false);

  const onChangeProgressEnabled = useCallback(
    (value: boolean) => setIsEnabledProgress(value),
    [],
  );

  const contextValue = useMemo(
    () => ({
      isEnabledProgress,
      onChangeProgressEnabled,
    }),
    [isEnabledProgress, onChangeProgressEnabled],
  );

  return (
    <ProgressContext.Provider value={contextValue}>
      {children}
    </ProgressContext.Provider>
  );
};

function useProgressContext(): ProgressContextType {
  const context = React.useContext(ProgressContext);
  if (context === undefined) {
    throw new Error(
      'useProgressContext must be used within a ProgressContextProvider',
    );
  }
  return context;
}

export { ProgressContextProvider, useProgressContext };
