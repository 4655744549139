const WHITE = '#FFFFFF';
const BLACK = '#000000';
const RED = '#E22F2F';
const DARK_BLUE_BG = '#232855';
const SKYBLUE = '#21B8F0';
const GREEN = '#17A94E';

const colors = {
  absoluteDark: BLACK,
  defaultGray: '#666666',

  toast: {
    success: GREEN,
    error: RED,
    text: WHITE,
  },

  appBg: {
    primaryBg: WHITE,
    secondaryBg: WHITE,
    blueBg: DARK_BLUE_BG,
    loaderBg: DARK_BLUE_BG,
  },
  header: {
    logo: WHITE,
    text: DARK_BLUE_BG,
    primaryBg: '#2D3B86',
    secondaryBg: SKYBLUE,
    profile: WHITE,
  },
  cardLarge: {
    bg: WHITE,
    text: RED,
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
  },
  cardSmall: {
    bg: DARK_BLUE_BG,
    text: WHITE,
    buttonBg: SKYBLUE,
    buttonBgActive: WHITE,
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
    title: RED,
    description: '#909090',
  },
  tile: {
    bg: WHITE,
    text: RED,
    border: '#D9D9D9',
  },
  timer: {
    text: RED,
    image: '#666666',
    imageActive: RED,
  },
  section: {
    title: RED,
    leftImage: {
      title: RED,
      subtitle: RED,
      description: RED,
    },
  },
  carousel: {
    bullet: 'rgba(0, 0, 0, 0.5)',
    bulletActive: 'rgba(0, 0, 0, 0.9)',
  },
  langSwitcher: {
    bg: '#A01313',
    border: '#A01313',
    text: WHITE,
  },
  input: {
    border: '#D9D9D9',
    text: BLACK,
    placeholder: '#8A8AB2',
    invalid: RED,
    cancel: '#D9D9D9',
    hint: '#D9D9D9',
    success: GREEN,
    eye: '#D9D9D9',
  },
  signup: {
    title: WHITE,
    text: WHITE,
    formBorder: DARK_BLUE_BG,
    checkboxText: WHITE,
  },
  login: {
    title: WHITE,
    text: WHITE,
    formBorder: DARK_BLUE_BG,
  },
  profile: {
    title: WHITE,
    text: WHITE,
    formBorder: DARK_BLUE_BG,
  },
  resetPassword: {
    title: WHITE,
    text: WHITE,
    formBorder: DARK_BLUE_BG,
  },
  forgotPassword: {
    title: WHITE,
    text: WHITE,
    formBorder: DARK_BLUE_BG,
    success: GREEN,
  },
  unsubscribe: {
    title: WHITE,
    text: WHITE,
    formBorder: DARK_BLUE_BG,
    iconButton: WHITE,
  },
  select: {
    bg: WHITE,
    border: '#D9D9D9',
    text: BLACK,
    menuBg: WHITE,
    placeholder: '#D9D9D9',
    arrow: '#262626',
    invalid: RED,
    menu: WHITE,
  },
  sidebar: {
    bg: '#2D3B86',
    close: WHITE,
    logo: WHITE,
    text: WHITE,
  },
  button: {
    defaultText: WHITE,
    primary: {
      bg: SKYBLUE,
      border: '#7BD56D',
      disabledBg: SKYBLUE,
      text: WHITE,
    },
    secondary: {
      bg: GREEN,
      border: '#EE3333',
      disabledBg: GREEN,
      text: WHITE,
    },
  },
};

export default colors;
