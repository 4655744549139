import React, { createContext, useContext, useMemo } from 'react';

import { useGetGameZoneTemplateQuery } from '@/services/api.service';

import { PageLoader } from '@/elements/Loaders/page-loader';

import { GameZoneTemplateType } from '@/types/domain';

export type GameZoneTemplateContextType = {
  template: GameZoneTemplateType | [];
};

const GameZoneTemplateContext = createContext<GameZoneTemplateContextType>({
  template: [],
});

const GameZoneTemplateContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { data, isFetching } = useGetGameZoneTemplateQuery();

  const template = useMemo(() => data?.sections || [], [data]);

  return (
    <GameZoneTemplateContext.Provider value={{ template }}>
      {children}
      {isFetching && <PageLoader />}
    </GameZoneTemplateContext.Provider>
  );
};

function useGameZoneTemplateContext(): GameZoneTemplateContextType {
  const context = useContext(GameZoneTemplateContext);
  if (context === undefined) {
    throw new Error(
      'useGameZoneTemplateContext must be used within a GameZoneTemplateContextProvider',
    );
  }
  return context;
}

export { GameZoneTemplateContextProvider, useGameZoneTemplateContext };
