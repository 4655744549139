import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  variants: {
    primary: {
      field: {
        width: '100%',
        padding: '14px 20px',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: 'input.text',
        borderRadius: '8px',
        backgroundColor: '#fff',
        _placeholder: {
          color: 'input.placeholder',
        },
        _focus: {},
        _invalid: {
          border: '1px solid',
          borderColor: 'input.invalid',
        },
      },
    },
    readonly: {
      field: {
        backgroundColor: 'input.placeholder',
      },
    },
  },
};

export default Input;
