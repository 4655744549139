import React, { useMemo, useState } from 'react';

import {
  Box,
  Image,
  useTheme,
  useMediaQuery,
  Flex,
  Button,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserIcon } from '@/assets/icons/UserIcon';
import { ReactComponent as BackArrow } from '@/assets/icons/backArrow.svg';
import clatchbetHeaderLogo from '@/assets/images/ClutchBet/clutchbet-logo.png';
import Burger from '@/assets/images/OlyBet/icon/burger.svg';

import { useSidebarContext } from '@/context/sidebar.context';
import { useUserContext } from '@/context/user.context';

import { MediaQueriesEnum } from '@/enum/mediaQueries.enum';
import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { triggerGTAGEvent } from '@/utils/analyticTracks';

const Header: React.FC = () => {
  const [isDesktop] = useMediaQuery(MediaQueriesEnum.isDesktop, { ssr: false });
  const { onSidebarOpen } = useSidebarContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { user } = useUserContext();
  const { value: jwt, removeItem: removeJWT } = useLocalStorage<string>(
    StorageKeysEnum.jwt,
  );
  const { removeItem: removeUserID } = useLocalStorage<string>(
    StorageKeysEnum.user_id,
  );

  const [logoLoaded, setLogoLoaded] = useState(false);

  const goToSignup = () => {
    triggerGTAGEvent('signup.step1');
    navigate(RoutesEnum.REGISTRATION);
  };

  const goToLogin = () => {
    navigate(RoutesEnum.LOGIN);
  };

  const goToHome = () => {
    navigate(RoutesEnum.HOME);
  };

  const handleLogout = () => {
    removeJWT();
    removeUserID();
  };

  const isHeaderShown = useMemo(
    () => pathname !== RoutesEnum.SSOENTRY,
    [pathname],
  );

  const isBackButtonVisible = useMemo(
    () => pathname === RoutesEnum.PROFILE,
    [pathname],
  );

  return (
    <Box
      as='header'
      display={isHeaderShown ? 'block' : 'none'}
      left='0'
      position='fixed'
      top='0'
      w='100%'
      zIndex='997'
    >
      <Box background={theme.colors.header.primaryBg} h={24} px='20px' w='100%'>
        <Flex
          align='center'
          h='100%'
          justify='space-between'
          position='relative'
          w='100%'
        >
          {!isDesktop && (
            <Flex gap='10px'>
              <Image
                alt='menu-icon'
                cursor='pointer'
                onClick={onSidebarOpen}
                src={Burger}
              />
              {isBackButtonVisible && (
                <BackArrow cursor='pointer' onClick={() => navigate(-1)} />
              )}
            </Flex>
          )}

          <Box mx='auto' w={isDesktop ? '224px' : '160px'} zIndex='1'>
            <Button onClick={goToHome} p='0' variant='ghost'>
              <Image
                alt=''
                display={logoLoaded ? 'block' : 'none'}
                onLoad={() => setLogoLoaded(true)}
                src={clatchbetHeaderLogo}
              />
            </Button>
          </Box>

          {isDesktop && (
            <Flex
              align='center'
              justify='flex-end'
              position='absolute'
              top='50%'
              transform='translateY(-50%)'
              w='100%'
            >
              {jwt ? (
                <>
                  {user && (
                    <Button
                      alignItems='center'
                      color={theme.colors.header.profile}
                      display='flex'
                      fontSize='17px'
                      fontWeight='700'
                      justifyContent='center'
                      leftIcon={<UserIcon />}
                      onClick={() => navigate(RoutesEnum.PROFILE)}
                      px='5px'
                      variant='unstyled'
                      w='150px'
                    >
                      <Text noOfLines={1}>{user.username}</Text>
                    </Button>
                  )}
                  <Button onClick={handleLogout} size='md' variant='primary'>
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <Button onClick={goToLogin} size='md' variant='primary'>
                    Login
                  </Button>
                  <Button
                    ml={4}
                    onClick={goToSignup}
                    size='md'
                    variant='secondary'
                  >
                    Join Now
                  </Button>
                </>
              )}
            </Flex>
          )}
        </Flex>
      </Box>

      {/* <Flex
        align='center'
        background={theme.colors.header.secondaryBg}
        h='44px'
        justify='center'
        mt='-1px'
        w='100%'
      >
        <Text color={theme.colors.header.text} fontSize='17px' fontWeight='400'>
          Over{' '}
          <Box as='span' color={theme.colors.header.text} fontWeight='700'>
            $1,000,000
          </Box>{' '}
          prize value won
        </Text>
      </Flex> */}
    </Box>
  );
};

export default Header;
