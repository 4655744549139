const radius = {
  smFull: '16px',
  smBottomBottom: '0 0 16px 16px',
  smTopTop: '16px 16px 0 0',

  mdFull: '33px',
  mdBottomBottom: '0 0 33px 33px',
  mdTopTop: '33px 33px 0 0',

  lgFull: '50px',
};

export default radius;
