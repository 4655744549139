import EST from '@/assets/icons/countries/EST.svg';
import FRN from '@/assets/icons/countries/FRN.png';
import GBR from '@/assets/icons/countries/GBR.svg';
import LAT from '@/assets/icons/countries/LAT.svg';
import RUS from '@/assets/icons/countries/RUS.png';
import SVK from '@/assets/icons/countries/SVK.svg';

import { ENABLED_LANGUAGES } from '@/utils/constants';

export const enabledLanguages = ENABLED_LANGUAGES?.split(',') || ['en'];

export const countryFlagByName = (name: (typeof enabledLanguages)[number]) => {
  switch (name.toLowerCase()) {
    case 'ee':
      return EST;
    case 'lv':
      return LAT;
    case 'sk':
      return SVK;
    case 'ru':
      return RUS;
    case 'fr':
      return FRN;
    case 'en':
    default:
      return GBR;
  }
};
